import { BehaviorSubject, throwError } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "@angular/router";
const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }),
};
export class LoginService {
    constructor(http, config, router) {
        this.http = http;
        this.config = config;
        this.router = router;
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    handleError(error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
    extractData(res) {
        const body = res;
        return body || {};
    }
    // tslint:disable-next-line:contextual-lifecycle use-lifecycle-interface
    ngOnInit() {
        this.config.redirectTools();
    }
    get currentUserValue() {
        return this.currentUserSubject.value;
    }
    login(email, password) {
        return this.http.post(this.config.urlDEV + '/login', { email, password })
            .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user.status === 'success') {
                if (user.change_password == 0) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('tokenCurrentConnected', JSON.stringify(user.token));
                    localStorage.setItem('site_id', user.site_id);
                    this.currentUserSubject.next(user);
                    return user;
                }
                else {
                    this.currentUserSubject.next(user);
                    return user;
                }
            }
            else {
                return;
            }
            // this.router.navigate(['/login'], { queryParams: { returnUrl: "state.url" }});
            return false;
        }));
    }
    loginWithToken(id, token) {
        return this.http.post(this.config.urlDEV + '/login_with_token', { id, token })
            .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user.status === 'success') {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem('tokenCurrentConnected', JSON.stringify(user.token));
                localStorage.setItem('site_id', user.site_id);
                this.currentUserSubject.next(user);
                return user;
            }
            else {
                return;
            }
            // this.router.navigate(['/login'], { queryParams: { returnUrl: "state.url" }});
            return false;
        }));
    }
    logout_take_in_hand() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('tokenCurrentConnected');
        localStorage.removeItem('profile_id');
        localStorage.removeItem('site_id');
        localStorage.removeItem('takeInHand');
        localStorage.removeItem('connect_like_verificateur');
        localStorage.removeItem('admin_login');
        localStorage.removeItem('takeInHandOf');
        this.currentUserSubject.next(null);
        this.router.navigate(['/']);
    }
    // tslint:disable-next-line:variable-name
    logout() {
        const data = {};
        return this.http.post(this.config.urlDEV + '/logout', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    sso_connexion_lms_to_certikap(data) {
        return this.http.post(this.config.urlDEV + '/lms/sso_connexion', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    connexion_with_login(data) {
        return this.http.post(this.config.urlDEV + '/connexion/login', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i3.Router)); }, token: LoginService, providedIn: "root" });

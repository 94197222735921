<h2>Modifier votre mot de passe</h2>
<form [formGroup]="passwordForm" (ngSubmit)="onSave()">
  <!-- Champ pour le nouveau mot de passe -->
  <mat-form-field appearance="fill">
    <mat-label>Nouveau mot de passe</mat-label>
    <input matInput type="password" formControlName="newPassword" required>
    <mat-error *ngIf="passwordForm.get('newPassword')?.hasError('required')">
      Le nouveau mot de passe est requis.
    </mat-error>
  </mat-form-field>

  <!-- Champ pour confirmer le nouveau mot de passe -->
  <mat-form-field appearance="fill">
    <mat-label>Confirmer le nouveau mot de passe</mat-label>
    <input matInput type="password" formControlName="confirmPassword" required>
    <mat-error *ngIf="passwordForm.get('confirmPassword')?.hasError('required')">
      La confirmation du mot de passe est requise.
    </mat-error>
  </mat-form-field>

  <!-- Boutons pour enregistrer ou annuler -->
  <div mat-dialog-actions align="end">
    <button class="btn btn-primary" mat-raised-button type="submit">Enregistrer</button>
  </div>
</form>

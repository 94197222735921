import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { JwPaginationModule } from 'jw-angular-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ɵs } from '@ng-select/ng-select';
import { MatFormFieldModule, MatSelectModule, MatSortModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { InspectorComponent } from './dashboard/inspector/inspector.component';
import { AdministratorComponent } from './dashboard/administrator/administrator.component';
import { ProfileComponent } from './dashboard/inspector/profile/profile.component';
import { SearchComponent } from './dashboard/inspector/search/search.component';
import { MenuComponent } from './dashboard/inspector/menu/menu.component';
import { HeaderComponent } from './dashboard/template/header/header.component';
import { FooterComponent } from './dashboard/template/footer/footer.component';
import { SidebarComponent } from './dashboard/template/sidebar/sidebar.component';
import { PageNonTrouveeComponent } from './pages/page-non-trouvee/page-non-trouvee.component';
import { AdminProfileComponent } from './dashboard/administrator/admin-profile/admin-profile.component';
import { ClientComponent } from './dashboard/administrator/client/client.component';
import { UserComponent } from './dashboard/administrator/user/user.component';
import { SuperviseurComponent } from './dashboard/administrator/superviseur/superviseur.component';
import { EditUserComponent } from './dashboard/administrator/user/edit-user/edit-user.component';
import { VerificateurComponent } from './dashboard/administrator/verificateur/verificateur.component';
import { IntervenantComponent } from './dashboard/administrator/intervenant/intervenant.component';
import { ResultIntervenantComponent } from './dashboard/inspector/result-intervenant/result-intervenant.component';
import { ForgetComponent } from './pages/forget/forget.component';
import { NewPasswortComponent } from './pages/new-passport/new-passwort.component';
import { CertificationComponent } from './dashboard/administrator/certification/certification.component';
import { VerificationComponent } from './dashboard/administrator/verification/verification.component';
import { AddCertificationComponent } from './dashboard/administrator/certification/add-certification/add-certification.component';
import { EditCertificationComponent } from './dashboard/administrator/certification/edit-certification/edit-certification.component';
import { SitesCertificationComponent } from './dashboard/administrator/certification/sites-certification/sites-certification.component';
// tslint:disable-next-line:max-line-length
import { VerificationCertificationComponent } from './dashboard/administrator/certification/verification-certification/verification-certification.component';
import { AddVerificationComponent } from './dashboard/administrator/verification/add-verification/add-verification.component';
import { SousCertificationComponent } from './dashboard/administrator/certification/sous-certification/sous-certification.component';
// tslint:disable-next-line:max-line-length
import { EditSousCertificationComponent } from './dashboard/administrator/certification/sous-certification/edit-sous-certification/edit-sous-certification.component';
import { EditVerificationComponent } from './dashboard/administrator/verification/edit-verification/edit-verification.component';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule } from '@angular/material/dialog';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { ExportComponent } from './dashboard/administrator/export/export.component';
// tslint:disable-next-line:max-line-length
import {SiteSouscertificationComponent} from './dashboard/administrator/certification/sous-certification/site-souscertification/site-souscertification.component';
// tslint:disable-next-line:max-line-length
import {VerificationSouscertificationComponent} from './dashboard/administrator/certification/sous-certification/verification-souscertification/verification-souscertification.component';
import { OrderModule } from 'ngx-order-pipe';
import { FooterVerificateurComponent } from './dashboard/inspector/footer-verificateur/footer-verificateur.component';
import { SsoConnectionComponent } from './pages/sso-connection/sso-connection.component';
import { CertificatsComponent } from './dashboard/administrator/intervenant/certificats/certificats.component';
import { FildarianeComponent } from './dashboard/administrator/fildariane/fildariane.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { IndexFormComponent } from './dashboard/administrator/form/index-form/index-form.component';
import { CreateFormComponent } from './dashboard/administrator/form/create-form/create-form.component';
import { EditFormComponent } from './dashboard/administrator/form/edit-form/edit-form.component';
import { NgDragDropModule } from 'ng-drag-drop';
import { FormBuilderComponent } from './dashboard/administrator/form/form-builder/form-builder.component';
import { AutoInscriptionComponent } from './dashboard/administrator/form/auto-inscription/auto-inscription.component';
import { NlmkFormComponent } from './dashboard/administrator/form/nlmk-form/nlmk-form.component';
import { AutoInscriptionStartComponent } from './dashboard/administrator/form/auto-inscription-start/auto-inscription-start.component';
import {ShortUrlPipe} from './pipes/short-url.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import { InspectorExportComponent } from './dashboard/inspector/inspector-export/inspector-export.component';
import { ImportComponent } from './dashboard/administrator/import/import/import.component';
import { EditChoiceScreenComponent } from './dashboard/administrator/choice-screen/edit-choice-screen/edit-choice-screen.component';
import { CreateChoiceScreenComponent } from './dashboard/administrator/choice-screen/create-choice-screen/create-choice-screen.component';
import {ListChoiceScreenComponent} from "./dashboard/administrator/choice-screen/list-choice-screen/list-choice-screen.component";
import {AuthInterceptor} from "./services/auth-interceptor.service";

//EKAMPUS
import {FooterEkampusComponent} from "./EKAMPUS/dashboard/template/footer/footerEkampus.component";
import {HeaderEkampusComponent} from "./EKAMPUS/dashboard/template/header/headerEkampus.component";
import {FildarianeEKAMPUSComponent} from "./EKAMPUS/dashboard/template/fildariane/fildarianeEKAMPUS.component";
import {ForgetEkampusComponent} from "./EKAMPUS/pages/forget/forgetEkampus.component";
import {LoginEkampusComponent} from "./EKAMPUS/pages/login/loginEkampus.component";

//IP
import {FooterIPComponent} from "./IP/dashboard/template/footer/footerIPComponent.component";
import {HeaderIPComponent} from "./IP/dashboard/template/header/headerIP.component";
import {FildarianeIPComponent} from "./IP/dashboard/template/fildariane/fildarianeIP.component";
import {ForgetIPComponent} from "./IP/pages/forget/forgetIP.component";
import {LoginIPComponent} from "./IP/pages/login/loginIP.component";
import {SidebarIPComponent} from "./IP/dashboard/template/sidebar/sidebarIP.component";
import {SidebarEkampusComponent} from "./EKAMPUS/dashboard/template/sidebar/sidebarEkampus.component";
import { CatalogManagementComponent } from "./dashboard/administrator/catalog-management/catalog-management.component";
import { CatalogListComponent } from './dashboard/administrator/catalog-list/catalog-list.component';
import { CreateCatalogManagementComponent } from './dashboard/administrator/catalog-management/create-catalog-management/create-catalog-management.component';
import { EditCatalogManagementComponent } from './dashboard/administrator/catalog-management/edit-catalog-management/edit-catalog-management.component';
import {CreateChapitreComponent} from "./dashboard/administrator/catalog-management/chapitre/create-chapitre/create-chapitre.component";
import {EditChapitreComponent} from "./dashboard/administrator/catalog-management/chapitre/edit-chapitre/edit-chapitre.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatRadioModule} from "@angular/material/radio";
import { PasswordUpdatePopupComponent } from './dashboard/template/password-update-popup/password-update-popup.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InspectorComponent,
    AdministratorComponent,
    ProfileComponent,
    SearchComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    PageNonTrouveeComponent,
    AdminProfileComponent,
    ClientComponent,
    UserComponent,
    SuperviseurComponent,
    EditUserComponent,
    VerificateurComponent,
    IntervenantComponent,
    ResultIntervenantComponent,
    ForgetComponent,
    NewPasswortComponent,
    CertificationComponent,
    VerificationComponent,
    AddCertificationComponent,
    EditCertificationComponent,
    SitesCertificationComponent,
    VerificationCertificationComponent,
    AddVerificationComponent,
    SousCertificationComponent,
    EditSousCertificationComponent,
    EditVerificationComponent,
    PasswordStrengthComponent,
    ExportComponent,
    SiteSouscertificationComponent,
    VerificationSouscertificationComponent,
    FooterVerificateurComponent,
    SsoConnectionComponent,
    CertificatsComponent,
    FildarianeComponent,
    IndexFormComponent,
    CreateFormComponent,
    EditFormComponent,
    FormBuilderComponent,
    AutoInscriptionComponent,
    NlmkFormComponent,
    AutoInscriptionStartComponent,
    ShortUrlPipe,
    SafeHtmlPipe,
    InspectorExportComponent,
    ImportComponent,
    ListChoiceScreenComponent,
    EditChoiceScreenComponent,
    CreateChoiceScreenComponent,
    //EKAMPUS
    HeaderEkampusComponent,
    FooterEkampusComponent,
    FildarianeEKAMPUSComponent,
    ForgetEkampusComponent,
    LoginEkampusComponent,
    SidebarEkampusComponent,
    //IP
    FooterIPComponent,
    HeaderIPComponent,
    FildarianeIPComponent,
    ForgetIPComponent,
    LoginIPComponent,
    SidebarIPComponent,
    CreateChoiceScreenComponent,
    CatalogManagementComponent,
    CatalogListComponent,
    CreateCatalogManagementComponent,
    EditCatalogManagementComponent,
    CreateChapitreComponent,
    EditChapitreComponent,
    PasswordUpdatePopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    PasswordStrengthMeterModule,
    JwPaginationModule,
    NgxPaginationModule,
    NgSelectModule,
    MatSelectModule,
    MatSortModule,
    MatFormFieldModule,
    DragDropModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    MatIconModule,
    NgDragDropModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    OrderModule, MatExpansionModule, MatInputModule, MatDialogModule, MatButtonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    NgxSpinnerService,
    NgSelectConfig,
    ɵs,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  entryComponents: [
    PasswordUpdatePopupComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

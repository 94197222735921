import { Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors  } from '@angular/forms';
import Swal from 'sweetalert2';
import {UserService} from "../../../services/user.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-password-update-popup',
  templateUrl: './password-update-popup.component.html',
  styleUrls: ['./password-update-popup.component.css']
})
export class PasswordUpdatePopupComponent implements OnInit {
  passwordForm: FormGroup;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<PasswordUpdatePopupComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.passwordForm = this.fb.group({
      newPassword: [''],
      confirmPassword: ['']
    });
  }

  // Fonction de validation pour le mot de passe
  verifyPassword(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';

    // Vérifier les conditions du mot de passe
    const minLength = value.length >= 9;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[\W_]/.test(value);

    // Vérifie que toutes les conditions sont remplies
    const passwordValid = minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;

    return passwordValid ? null : { passwordStrength: true };
  }

  onSave(): void {
    let newPassword = this.passwordForm.get('newPassword').value;
    let confirmPassword = this.passwordForm.get('confirmPassword').value;
    if (newPassword === confirmPassword) {
      if(this.verifyPassword(newPassword)){
      // Mot de passe valide
      const body = {
        user_login: this.data.currentUser.login,
        new_password: this.passwordForm.get('newPassword').value,
      };
      this.userService.change_password_popup(body)
        .subscribe(res => {
          const message = res.message;
          if(res.success == true){
            localStorage.setItem('currentUser', JSON.stringify(this.data.currentUser));
            localStorage.setItem('tokenCurrentConnected', JSON.stringify(this.data.tokenCurrentConnected));
            localStorage.setItem('site_id', this.data.site_id);
            localStorage.setItem('profile_id', this.data.profile_id);
            if (res.profile_id === 10 || res.profile_id === 8) {
              window.location.reload();
              this.router.navigate(['dashboard/inspector']);
              return false;
            }else{
              window.location.reload();
              this.router.navigate(['dashboard/administrator']);
            }
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: message,
            });
          }
          }, (err) => {
          console.log(err);
        });
    }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Votre mot de passe doit contenir au moins 9 caractères, une majuscule, une minuscule, un chiffre, et un caractère spécial.',
        });
      }
    }else{
        Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Les mots de passes ne sont pas les mêmes ou sont vides',
      });
    }
  }

  ngOnInit(): void {
  }

}
